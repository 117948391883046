body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--background-dim, #FFFFFF);
}

.container-fluid {
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: flex-start;
  background-color: var(--background-dim, #FFFFFF);
}

.footer {
  background-color: #000000;
  color: rgba(255, 255, 255, 0.8);
  font-size: smaller;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: auto;
  max-height: 50px;
  vertical-align: bottom;
  position: fixed;
  bottom: 0;
  z-index: 1010;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.45) 0px -5px 20px 0px;
}

.footer a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}

.ql-editor {
  min-height: 200px;
}